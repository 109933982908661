@import "../constanst/variables.scss";
#maindw {
    .main-text {
        padding: 1em 0;
        font-size: 2.3em;
        font-family: 'Arima Madurai', cursive;
        font-weight: 900;
    }
    .row {
        padding: 0;
        margin-right: 0;
        margin-left: 0;
    }
    .head {
        background-image: url('../../multimedia/images/develop/blueCode.jpg');
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 0 0 0 60%;
        .opac {
            text-align: right;
            background-color: rgba($blue, 0.9);
            border-radius: 0 0 0 55%;
            padding: 15em 3em 5em 3em;
            // background-color: rgba($black, 0.5);
        }
        h1,
        h2 {
            color: #fff;
        }
        h2 {
            font-size: 1.5em;
        }
        .btn {
            color: #fff;
            border: 2px solid $naranja;
            margin: 2em 0 1em 0;
            padding: 0.5em 2em;
            font-size: 1.3em;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -ms-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            &:hover {
                background-color: rgba($black, 0.2);
                border: 2px solid $naranja;
                color: #fff;
            }
            &:focus {
                background-color: rgba($black, 0.2);
                border: 2px solid $naranja;
                box-shadow: none;
                color: #fff;
            }
            &:active {
                background-color: rgba($black, 0.2)!important;
                border: 2px solid $naranja!important;
                box-shadow: none!important;
                color: #fff!important;
            }
        }
    }
    .title {
        margin-top: 3em;
        font-size: 3em;
        color: $naranja;
    }
    .disweb {
        margin-top: 15em;
        background-image: url('../../multimedia/images/develop/twomonitors.jpg');
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-attachment: fixed;
        // border-top: 1px solid $black;
        // border-bottom: 1px solid $black;
        .up {
            margin-top: -10em;
            margin-bottom: 5em;
            border-top: 0.5em solid $naranja;
        }
        .down {
            margin-top: 5em;
            margin-bottom: -10em;
            border-bottom: 0.5em solid $naranja;
        }
        .caract {
            padding: 1em;
            margin-left: 1em;
            margin-right: 1em;
            h3 {
                text-align: center;
                margin: 1em 0;
                font-weight: bold;
            }
            color: #fff;
            background-color: rgba($blue,
            0.9);
            .icon {
                padding: 1em;
            }
            .fa-vector-square,
            .fa-users,
            .fa-wordpress,
            .fa-joomla,
            .fa-mobile-alt,
            .fa-align-center,
            .fa-user-secret {
                font-size: 3em;
            }
            &:hover {
                h3 {
                    color: $naranja;
                }
            }
        }
    }
    .message {
        // background-color: $black;
        padding: 2em 8em;
        margin-top: 14em;
        // color: #fff;
        .lateral {
            h4 {
                margin: 0 0 1em 0;
                color: $naranja;
            }
            p {
                padding: 0 0.5em;
                color: $gray;
            }
        }
        .center {
            // vertical-align: center;
            // font-weight: bold;
            font-size: 1.6em;
            font-family: Helvetica;
            color: $gray;
            border-bottom: 5px dotted $naranja;
        }
        .corange {
            background-color: $naranja;
            width: 3em;
            height: 3em;
            border-radius: 50%;
            margin: 0 auto;
            padding: 0;
        }
        .cblue {
            background-color: $blueNoHover;
            width: 3em;
            height: 3em;
            border-radius: 50%;
            margin: 0 auto;
            padding: 0;
        }
    }
    .multsols {
        background-image: url('../../multimedia/images/develop/productos/redbg.png');
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: 50% 50%;
        margin-top: 3em;
        padding: 2em 0;
        color: #fff;
        overflow: hidden;
        .imgsols {
            overflow: hidden;
            border-radius: 5px;
            -webkit-box-shadow: 10px 10px 43px 0px rgba($black, 0.75);
            -moz-box-shadow: 10px 10px 43px 0px rgba($black, 0.75);
            box-shadow: 10px 10px 43px 0px rgba($black, 0.75);
            transition: all 1s ease;
        }
        .obsder {
            padding: 1em 0;
            background-color: rgba($blue, 0.8);
            // margin: 1em 0;
            color: #fff;
            overflow: hidden;
            h3 {
                color: $naranja;
                padding-bottom: 0.5em;
                font-size: 1.5em;
            }
            p {
                font-size: 1.1em;
            }
            .borde {
                padding: 1em 2em;
                margin: 0 2em;
                border-right: 1em solid $naranja;
                transition: all 1S ease;
            }
            .borde2 {
                padding: 1em 2em;
                margin: 0 2em;
                border-left: 1em solid $blueNoHover;
                transition: all 2s ease;
            }
            &:hover .imgsols {
                transform: scale(1.1);
            }
            &:hover .borde {
                border-right: 2em solid $naranja;
            }
            &:hover .borde2 {
                border-left: 2em solid $blueNoHover;
            }
        }
    }
    .contEcommerce {
        padding: 0;
        margin-bottom: 5em;
        h2 {
            margin: 1em 0;
            color: $naranja;
        }
        .box {
            position: relative;
            // padding-left: 1em;
            // padding-right: 1em;
            height: 500px;
            background: #000;
            box-shadow: 0 30px 30px rgba(0, 0, 0, 0.5);
            .imgBx {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: 0.5s;
                }
            }
            .content {
                position: absolute;
                bottom: 20px;
                left: 10%;
                width: 80%;
                height: 60px;
                background: #fff;
                transition: 0.5s;
                overflow: hidden;
                padding: 20px;
                box-sizing: border-box;
                transform: translateY(40px);
                h3 {
                    // margin: 0;
                    padding: 0;
                    font-size: 1.2em;
                    margin-bottom: 1em;
                    font-family: 'Righteous', cursive;
                    color: $blueNoHover;
                }
                ul {
                    opacity: 0;
                    // padding-left: 3em;
                    padding-right: 1.2em;
                    list-style: none;
                    li {
                        // margin: 10px 0 0;
                        padding: 10px 0;
                        font-size: 1em;
                        transition: 0.5;
                        line-height: 1.2em;
                        text-align: justify;
                        color: #fff;
                        border-bottom: 1px solid $blue;
                    }
                }
            }
            &:hover .content {
                width: 100%;
                height: 100%;
                bottom: 0;
                left: 0;
                background: rgba($black, 0.2);
                transform: translateY(0px);
                h3 {
                    color: $naranja;
                }
                ul {
                    opacity: 1;
                    transition-delay: 0.4s;
                }
            }
        }
    }
}

@import "./mediaqueries.scss"