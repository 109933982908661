@import "../constanst/variables.scss";
#mainMd {
    .main-text {
        padding: 1em 0;
        font-size: 2.3em;
        font-family: 'Arima Madurai', cursive;
        font-weight: 900;
        color: $naranja;
    }
    .row {
        padding: 0;
        margin-right: 0;
        margin-left: 0;
    }
    // .btn {
    //     margin-top: 1em;
    //     padding: 1em 5em;
    //     &.white {
    //         border: 3px solid $red;
    //         color: $red;
    //     }
    //     &.black {
    //         border: 1px solid $black;
    //         color: $black;
    //     }
    // }
    .head {
        background-image: url('../../multimedia/images/marketing/headerOnlineMarketing.jpg');
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 0 0 0 60%;
        .opac {
            text-align: right;
            background-color: rgba($red, 0.9);
            border-radius: 0 0 0 55%;
            padding: 15em 3em 5em 3em;
        }
        h1,
        h2 {
            color: #fff;
        }
        h2 {
            font-size: 1.5em;
        }
        .btn {
            color: #fff;
            border: 2px solid $naranja;
            margin: 2em 0 1em 0;
            padding: 0.5em 2em;
            font-size: 1.3em;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -ms-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            &:hover {
                background-color: rgba($black, 0.2);
                border: 2px solid $naranja;
                color: #fff;
            }
            &:focus {
                background-color: rgba($black, 0.2);
                border: 2px solid $naranja;
                box-shadow: none;
                color: #fff;
            }
            &:active {
                background-color: rgba($black, 0.2)!important;
                border: 2px solid $naranja!important;
                box-shadow: none!important;
                color: #fff!important;
            }
        }
        // .btn {
        //     margin: 3em 0 1em 0;
        //     padding: 1em 4em;
        //     border: 3px solid #ff4c4c;
        //     color: #ff4c4c;
        //     font-size: 1.3em;
        // }
    }
    .SsCamp {
        margin-top: 6em;
        .campannas {
            .campannasAdwords {
                background-image: url('../../multimedia/images/marketing/g_adds/possits.png');
                position: relative;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-attachment: fixed;
                .adwordsC {
                    // background-color: rgba($black, 0.7);
                    padding: 3em 0;
                    .img-thumbnail {
                        background-color: $naranja;
                        border: 1px solid $naranja;
                        transition: all 1s ease;
                        width: 80%;
                        &:hover {
                            -webkit-transform: scale(1.1);
                            transform: scale(1.1);
                        }
                    }
                    .text {
                        .titlesub {
                            font-size: 2.5em;
                            margin: 1em;
                            color: $red;
                        }
                        p {
                            font-size: 1.2em;
                            color: #fff;
                        }
                    }
                }
            }
            .campannasEmail {
                background-image: url('../../multimedia/images/marketing/emailmk/emails.jpg');
                position: relative;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                overflow: hidden!important;
                .emailmk {
                    background-color: rgba(#fff, 0.7);
                    padding: 3em 0;
                    color: #000;
                    overflow: hidden!important;
                    .img-thumbnail {
                        background-color: $naranja;
                        border: 1px solid $naranja;
                        transition: all 1s ease;
                        width: 80%;
                        &:hover {
                            -webkit-transform: scale(1.1);
                            transform: scale(1.1);
                        }
                    }
                    .text {
                        .titlesub {
                            font-size: 2.5em;
                            margin: 1em;
                            color: $red;
                        }
                        p {
                            font-size: 1.2em;
                        }
                    }
                }
            }
            .campannasCont {
                background-image: url('../../multimedia/images/marketing/contenidosmk/creatingContent.png');
                position: relative;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-attachment: fixed;
                .contsmk {
                    // background-color: rgba($black, 0.7);
                    padding: 3em 0;
                    color: #fff;
                    .img-thumbnail {
                        background-color: $naranja;
                        border: 1px solid $naranja;
                        transition: all 1s ease;
                        width: 80%;
                        &:hover {
                            -webkit-transform: scale(1.1);
                            transform: scale(1.1);
                        }
                    }
                    .text {
                        .titlesub {
                            font-size: 2.5em;
                            margin: 1em;
                            color: $red;
                        }
                        p {
                            font-size: 1.2em;
                        }
                    }
                }
            }
        }
    }
    .containerSeo {
        position: relative;
        margin-top: 5em;
        margin-bottom: 5em;
        .titulos {
            padding: 2em 0;
            .main-text2 {
                font-size: 2.3em;
                font-family: 'Arima Madurai', cursive;
                font-weight: 900;
                color: $naranja;
            }
            h3 {
                font-size: 1.5em;
                font-family: 'Arima Madurai', cursive;
                font-weight: 900;
                color: $gray;
            }
        }
        .box {
            box-sizing: border-box;
            // margin: 1em;
            position: relative;
            height: 500px;
            // background: #060251;
            // display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid $black;
            margin-top: 2em;
            margin-bottom: 2em;
            &:before {
                content: '';
                position: absolute;
                top: -2px;
                left: -2px;
                right: -2px;
                bottom: -2px;
                transform: skew(2deg, 2deg);
                z-index: -1;
                background: #fff;
                transition: all 1s ease;
            }
            &:hover:before {
                transform: skew(-2deg, -2deg);
            }
            &:before {
                background: linear-gradient(315deg, $naranja, $red);
            }
            // &:nth-child(2):before {
            //     background: linear-gradient(315deg, #89ff00, #00bcd4);
            // }
            // &:nth-child(3):before {
            //     background: linear-gradient(315deg, #e91e63, #5d02ff);
            // }
            // &:nth-child(4):before {
            //     background: linear-gradient(315deg, #ff0000, #ff0107);
            // }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                width: 50%;
                height: 100%;
                background: rgba($black, 0.05);
                pointer-events: none;
            }
            .content {
                position: relative;
                padding: 20px;
                transform: translateY(40px);
                h2 {
                    position: absolute;
                    top: 10px;
                    right: 20px;
                    margin: 0;
                    padding: 0;
                    font-size: 10em;
                    color: rgba(255, 255, 255, .25);
                    transition: 0.5s;
                    z-index: -1;
                }
                h3 {
                    margin: 0;
                    margin-bottom: 1em;
                    padding: 0;
                    font-size: 2em;
                    font-weight: 500;
                    color: $black;
                    z-index: 1;
                    display: inline-block;
                    border-bottom: 2px solid transparent;
                    transition: all 0.5s ease;
                }
                p {
                    margin: 0;
                    padding: 0;
                    color: #fff;
                    font-size: 1.1em;
                    text-align: center;
                    padding-top: 0.5em;
                    transition: all 0.5s ease;
                }
                .icono {
                    position: relative;
                    // margin: 20px 0 0 0;
                    // padding: 10px 20px;
                    text-decoration: none;
                    // border: 1px solid #fff;
                    display: inline-block;
                    color: #fff;
                    transition: 0.5s;
                    transform: translateY(-40px);
                    opacity: 0;
                    visibility: hidden;
                    margin: auto;
                    font-size: 5em;
                }
            }
            &:hover .content h2 {
                top: -60px;
            }
            &:hover .content h3 {
                border-bottom: 2px solid $black;
                font-size: 1.5em;
            }
            &:hover .content p {
                padding-top: 1em;
            }
            &:hover .content .icono {
                transform: translateY(20px);
                opacity: 1;
                visibility: visible;
                font-size: 3em;
            }
        }
    }
}

@import "./mediaqueries.scss";