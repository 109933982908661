@import "../constanst/variables.scss";
#mainSobreHL {
    .main-text {
        padding: 1em 0;
        font-size: 2.3em;
        font-family: 'Arima Madurai', cursive;
        font-weight: 900;
        color: $naranja;
    }
    .row {
        padding: 0;
        margin-right: 0;
        margin-left: 0;
    }
    .nopadding {
        padding: 0;
    }
    .head {
        background-image: url('../../multimedia/images/sobreHL/handslights.jpg');
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 0 0 0 60%;
        .opac {
            padding: 12em 3em 12em 3em;
            background-color: rgba($naranja, 0.9);
            border-radius: 0 0 0 55%;
        }
        span {
            font-family: 'Lato', sans-serif;
            font-weight: 900;
            font-size: 5em;
            color: #fff;
        }
    }
    .weare {
        margin: 3em 0 0 0;
        h1 {
            img {
                width: 10%;
            }
        }
    }
    .whowe {
        .main-text {
            margin-bottom: 0;
            padding-bottom: 0;
        }
        p {
            font-size: 1.2em;
            padding: 2em 0 3em 0;
            color: $gray;
        }
    }
    
    .whatwe {
        margin: 0em 0;
        background-image: url('../../multimedia/images/sobreHL/ligthsBlurDark.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center center;
        .bgw {
            padding: 10em 0;
            width: 100%;
            // background-color: rgba(#fff, 0.8);
            .mleft {
                border-right: 1px solid rgba($naranja, 0.9);
                h2 {
                    font-family: 'Raleway', sans-serif;
                    font-size: 2em;
                    margin-right: 2em;
                    color: rgba(#fff, 0.5);
                    text-transform: uppercase;
                }
            }
            p {
                color: #fff;
                font-size: 1.2em;
                padding: 1em 0;
                margin-right: 2em;
                margin-left: 2em;
                margin-bottom: 2em;
                b {
                    font-size: 1.5em;
                }
                &.tn {
                    color: $naranja;
                    // text-shadow: 1px 10px 10px $black;
                }
            }
            .btnhh {
                padding: 0.8em 3em;
                color: #fff;
                text-decoration: none;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
    .forme {
        margin: 5em 0;
        .mleft {
            border-right: 1px solid rgba($black, 0.2);
            h2 {
                font-family: 'Raleway', sans-serif;
                color: $naranja;
                margin-top: 2em;
                margin-bottom: 1em;
                font-weight: 500;
                text-transform: uppercase;
                margin-right: 1em;
                margin-left: 5em;
            }
            h3 {
                font-size: 1.5em;
                margin-right: 1em;
                margin-left: 10em;
                &.tn {
                    color: $gray;
                    border-bottom: 1px solid $naranja;
                }
            }
        }
        ul {
            list-style: none;
            color: $gray;
            li {
                padding-bottom: 1em;
                .fa-check {
                    color: $naranja;
                }
            }
        }
    }
    .nuestroproceso {
        background-color: #eff0f4;
        padding: 1em 5em 7em 5em;
        h3 {
            font-size: 1.5em;
            padding: 0.5em 0;
        }
        // background-image: url("../../src/imgs/Home/banner/blurtrianglelights.jpg");
        // background-attachment: fixed;
        // background-size: cover;
        // background-repeat: no-repeat;
        // background-position: center center;
        .el1,
        .el2,
        .el3,
        .el4,
        .el5 {
            border-radius: 10px;
            color: #fff;
            -webkit-box-shadow: 13px 20px 39px -5px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 13px 20px 39px -5px rgba(0, 0, 0, 0.75);
            box-shadow: 13px 20px 39px -5px rgba(0, 0, 0, 0.75);
            transition: all 0.5s ease;
            &:hover {
                -webkit-box-shadow: 13px 20px 39px -5px rgba(0, 0, 0, 1);
                -moz-box-shadow: 13px 20px 39px -5px rgba(0, 0, 0, 1);
                box-shadow: 13px 20px 39px -5px rgba(0, 0, 0, 1);
                border-radius: 100px;
                h3 {
                    color: $naranja;
                }
            }
            h3 {
                font-size: 1.8em;
                text-align: center;
            }
            .bgosc {
                border-radius: 10px;
                padding: 2em 3em;
                transition: all 0.5s ease;
                .icnPrs {
                    transition: all 0.5s ease;
                }
            }
            span {
                font-size: 5em;
            }
            p {
                font-size: 1.1em;
            }
        }
        .el4,
        .el5 {
            .bgosc {
                padding: 3em 5em;
                // background-color: rgba($black, 0.7);
            }
        }
        .el1 {
            background-image: url('../../multimedia/images/sobreHL/process/meetingblk.jpg');
            background-position: center center;
            background-size: cover;
            .icnPrs {
                color: $blue;
            }
            &:hover {
                .bgosc {
                    border-radius: 100px;
                    background-color: rgba($blue, 0.8);
                    .icnPrs {
                        color: $naranja;
                    }
                }
            }
        }
        .el2 {
            background-image: url('../../multimedia/images/sobreHL/process/Proposeblk.jpg');
            background-position: center center;
            background-size: cover;
            margin-top: 5em;
            .icnPrs {
                color: $red;
            }
            &:hover {
                .bgosc {
                    border-radius: 100px;
                    background-color: rgba($red, 0.8);
                    .icnPrs {
                        color: $naranja;
                    }
                }
            }
        }
        .el3 {
            background-image: url('../../multimedia/images/sobreHL/process/executionblk.jpg');
            background-position: center center;
            background-size: cover;
            margin-top: 10em;
            .icnPrs {
                color: $purple;
            }
            &:hover {
                .bgosc {
                    border-radius: 100px;
                    background-color: rgba($purple, 0.8);
                    .icnPrs {
                        color: $naranja;
                    }
                }
            }
        }
        .el4 {
            background-image: url('../../multimedia/images/sobreHL/process/publicationblk.jpg');
            background-position: center center;
            background-size: cover;
            margin-top: 3em;
            .icnPrs {
                color: $darkBlue;
            }
            &:hover {
                .bgosc {
                    border-radius: 100px;
                    background-color: rgba($darkBlue, 0.8);
                    .icnPrs {
                        color: $naranja;
                    }
                }
            }
        }
        .el5 {
            background-image: url('../../multimedia/images/sobreHL/process/Trophyblk.jpg');
            background-position: center center;
            background-size: cover;
            margin-top: 8em;
            .icnPrs {
                color: $blue;
            }
            .bgosc {
                padding: 3em;
            }
            &:hover {
                .bgosc {
                    border-radius: 100px;
                    background-color: rgba($blue, 0.8);
                    .icnPrs {
                        color: $naranja;
                    }
                }
            }
        }
    }
}

@import "./mediaqueries.scss";