@import '../../constanst/variables.scss';
// TODO: CHOOSE A CORRECT FONT FOR THE TITLE IN THIS SECTION
#contactFormSect {
    background-image: url("../../../multimedia/images/contacto/coffee Contact.png");
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-bottom: 5em;
    .bgblack {
        padding-top: 2em;
        h4 {
            color: #fff;
            font-size: 1.5em;
            font-family: 'Merienda One', cursive;
            margin-bottom: 1em;
        }
        .formulario {
            form {
                .input-group {
                    // margin: 2em 0em 2em 0em;
                    border-bottom: 2px solid $naranja;
                    &:hover {
                        box-shadow: none;
                        background-color: rgba(#fff, .2);
                        font-family: Arial;
                        &::placeholder {
                            color: rgba($naranja, .7);
                            font-family: Arial;
                        }
                        .frmIcon {
                            color: $naranja;
                        }
                    }
                    .form-control {
                        border-bottom: none;
                        font-size: 0.8em;
                        background-color: transparent;
                        border-top: 0px;
                        border-left: 0px;
                        border-right: 0px;
                        font-family: Arial;
                        font-weight: 300;
                        letter-spacing: 1px;
                        border-radius: 0px;
                        color: #fff;
                        &::placeholder {
                            color: rgba(#fff, .7);
                            font-family: Arial;
                            font-weight: 300;
                        }
                    }
                    .input-group-text {
                        background-color: transparent;
                        border: none;
                        position: relative;
                        justify-content: end;
                    }
                    .frmIcon {
                        display: inline-block;
                        color: #fff;
                        font-size: 1.5em;
                        transition-timing-function: linear;
                        -webkit-transition: all 0.3s;
                        -moz-transition: all 0.3s;
                        -ms-transition: all 0.3s;
                        -o-transition: all 0.3s;
                        transition: all 0.3s;
                    }
                }
                .sendbtn {
                    .frmIcon {
                        display: inline-block;
                        color: #fff;
                        font-size: 1.5em;
                        transition-timing-function: linear;
                        -webkit-transition: all 0.3s;
                        -moz-transition: all 0.3s;
                        -ms-transition: all 0.3s;
                        -o-transition: all 0.3s;
                        transition: all 0.3s;
                    }
                    &:hover {
                        .frmIcon {
                            color: $naranja;
                        }
                    }
                }
                .terminos {
                    color: #fff;
                    font-size: 0.7em;
                }
                .mensaje {
                    min-height: 120px;
                    max-height: 120px;
                }
            }
        }
        .errores {
            margin: 1em 0;
            font-size: 0.8em;
            visibility: hidden;
            p {
                margin-bottom: 0em;
            }
        }
    }
}

.spinnerLoading {
    margin-left: 0.5em;
    margin-top: 0.5em;
    position: absolute;
    z-index: 1000;
    color: #fff;
    font-size: 2em;
    animation: rotating 2s linear infinite;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@import "./mediaqueries.scss";