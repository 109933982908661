/*=============================================
ESCRITORIO SUPER GRANDE (XL revisamos en 1366px en adelante)
=============================================*/

// Extra Large
@media (min-width: 1200px) {}


/*=============================================
ESCRITORIO GRANDE O TABLET HORIZONTAL (LG revisamos en 1024px)
=============================================*/

// Large
@media (min-width: 992px) and (max-width: 1199px) {
    nav#navHL {
        .brandhl {
            a {
                #imgLogoNav {
                    min-width: 7em;
                }
            }
        }
        #navbarHome {
            ul {
                li {
                    padding: 0 0.5em;
                }
            }
        }
    }
}


/*=============================================
ESCRITORIO MEDIANO O TABLET VERTICAL (MD revisamos en 768px)
=============================================*/

// Medium
@media (min-width: 768px) and (max-width: 991px) {
    nav#navHL {
        .menuHL {
            margin-left: 0;
        }
    }
}


/*=============================================
MOVIL HORIZONTAL O MINI TABLET VERTICAL (revisamos en 576 y 767)
=============================================*/

// Small
@media (min-width: 576px) and (max-width: 767px) {
    nav#navHL {
        .brandhl {
            a {
                #imgLogoNav {
                    max-width: 15em;
                }
            }
        }
        .menuHL {
            margin-left: 0;
        }
    }
}


/*=============================================
MOVIL (XS revisamos en 320px)
=============================================*/

// extra small
@media (max-width: 575px) {
    nav#navHL {
        .brandhl {
            a {
                img {
                    width: 100%;
                    max-width: 10em;
                }
            }
        }
        .menuHL {
            margin-left: 0em;
        }
    }
}