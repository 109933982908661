@import "../../constanst/variables.scss";
.singleWork {
    margin: 2em 2em;
    position: relative!important;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 80%;
    height: 450px;
    transform-style: preserve-3d;
    perspective: 200px;
    transition: 0.5s;
    border-bottom: 5px solid $naranja;
    overflow: hidden;
    .front {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        backface-visibility: hidden;
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        transform: rotateX(0deg);
        transition: 0.5s;
        text-align: center;
        .titleWorkFront {
            color: $blue;
            padding: 1em 0 0.3em 0;
            margin-bottom: 1em;
            font-size: 1.1em;
            display: inline-block;
            vertical-align: top;
            zoom: 1;
            /* Fix for IE7 */
            *display: inline;
            /* Fix for IE7 */
            border-bottom: 1px solid $naranja;
        }
        img {
            width: 90%;
            -webkit-box-shadow: 0px -2px 23px 3px rgba(48, 74, 112, 1);
            -moz-box-shadow: 0px -2px 23px 3px rgba(48, 74, 112, 1);
            box-shadow: 0px -2px 23px 3px rgba(48, 74, 112, 1);
        }
    }
    .back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        backface-visibility: hidden;
        -webkit-transform: rotateX(180deg);
        -moz-transform: rotateX(180deg);
        -ms-transform: rotateX(180deg);
        -o-transform: rotateX(180deg);
        transform: rotateX(180deg);
        transition: 0.5s;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -80%;
            width: 100%;
            height: 100%;
            background: rgba(#000, 0.1);
        }
        .contentCard {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            text-align: center;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 20px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            h3 {
                margin: 0;
                padding: 0;
                font-size: 1.5em;
                color: $black;
                span {
                    font-size: 0.8em;
                    color: $naranja;
                }
            }
            img {
                width: 70%;
                margin-top: 1.5em;
                margin-bottom: 1em;
            }
            .btnSeeWork {
                margin-top: 1em;
                a {
                    text-decoration: none;
                    color: #fff;
                    padding: 0.5em 1em;
                    font-size: 1em;
                    border-radius: 50px 50px;
                    background: linear-gradient(to right, $yellow 10%, $naranja 20%, $red 30%, $purple 40%, $blue 50%, $blue 60%, $purple 70%, $red 80%, $naranja 90%, $yellow 100%);
                    background-size: 400%;
                    z-index: 1;
                    box-shadow: 0;
                    &:hover {
                        animation: txtColor 8s linear infinite;
                        box-shadow: 2px 2px 50px rgba(#fff, 0.3);
                    }
                }
            }
        }
    }
    &:hover .front {
        -webkit-transform: rotateX(-180deg);
        -moz-transform: rotateX(-180deg);
        -ms-transform: rotateX(-180deg);
        -o-transform: rotateX(-180deg);
        transform: rotateX(-180deg);
    }
    &:hover .back {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        transform: rotateX(0deg);
    }
    &:hover {
        border: 1px solid $blue;
    }
}

@keyframes txtColor {
    to {
        background-position: 300% center;
    }
}

@import "./mediaqueries.scss";