@import "../constanst/variables.scss";
#mainSM {
    .main-text {
        padding: 1em 0;
        font-size: 2.3em;
        font-family: 'Arima Madurai', cursive;
        font-weight: 900;
        color: $naranja;
    }
    .row {
        padding: 0;
        margin-right: 0;
        margin-left: 0;
    }
    .head {
        background-image: url('../../multimedia/images/socialMedia/social media.jpg');
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 0 0 0 60%;
        .opac {
            text-align: right;
            background-color: rgba($purple, 0.9);
            border-radius: 0 0 0 55%;
            padding: 15em 3em 5em 3em;
        }
        h1,
        h2 {
            color: #fff;
        }
        h2 {
            font-size: 1.5em;
        }
        .btn {
            color: #fff;
            border: 2px solid $naranja;
            margin: 2em 0 1em 0;
            padding: 0.5em 2em;
            font-size: 1.3em;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -ms-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            &:hover {
                background-color: rgba($black, 0.2);
                border: 2px solid $naranja;
                color: #fff;
            }
            &:focus {
                background-color: rgba($black, 0.2);
                border: 2px solid $naranja;
                box-shadow: none;
                color: #fff;
            }
            &:active {
                background-color: rgba($black, 0.2)!important;
                border: 2px solid $naranja!important;
                box-shadow: none!important;
                color: #fff!important;
            }
        }
        // .btn {
        //     margin: 3em 0 1em 0;
        //     padding: 1em 4em;
        //     border: 3px solid #ff4c4c;
        //     color: #ff4c4c;
        //     font-size: 1.3em;
        // }
    }
    .SsAdminRs {
        margin-top: 5em;
        .main-text {
            padding-bottom: 0;
        }
        .sbtlt {
            font-size: 1.5em;
            font-family: 'Arima Madurai', cursive;
            font-weight: 900;
            color: $gray;
            margin-bottom: 3em;
        }
        .upleft {
            margin-left: 5em;
            margin-top: -5em;
        }
        .upRight {
            margin-right: 5em;
            margin-top: -5em;
        }
        .pttitle {
            padding-top: 1em;
        }
        .goup {
            margin-top: 5em;
        }
        .titleF {
            color: #3b5998;
            font-family: 'Righteous', cursive;
            font-size: 2.5em;
        }
        .titleI {
            color: #e1306c;
            font-family: 'Righteous', cursive;
            font-size: 2.5em;
        }
        .titleY {
            color: $red;
            font-family: 'Righteous', cursive;
            font-size: 2.5em;
        }
        .titleT {
            color: #1da1f2;
            font-family: 'Righteous', cursive;
            font-size: 2.5em;
        }
        .text {
            padding: 1em 0;
            font-family: Arial;
            font-size: 1em;
        }
        .icono {
            font-size: 5em;
            &.fa-bullhorn {
                color: $blue;
            }
            &.fa-images {
                color: #5851db;
            }
            &.fa-video {
                color: $red;
            }
            &.fa-edit {
                color: #657786;
            }
        }
    }
    .socialCampains {
        margin-bottom: 3em;
        .main-text {
            margin-top: 1em;
            padding-bottom: 0!important;
        }
        .subtlt {
            font-size: 2em;
            color: $gray;
            margin-bottom: 1em;
            font-family: 'Merienda One', cursive;
        }
        .TitleEstSM {
            color: $purple;
            font-weight: bold;
            font-family: 'Arima Madurai', cursive;
            border-bottom: 2px solid $naranja;
            padding-bottom: 0.3em;
        }
        .icono {
            color: $black;
            font-size: 3em;
            margin-top: 0.5em;
        }
        .tEstSM {
            color: $gray;
            margin-bottom: 2em;
        }
    }
    .SMO {
        background: linear-gradient(250deg, $purple, #fff, #fff);
        color: $gray;
        margin: 5em 0;
        .main-text {
            padding-bottom: 0!important;
        }
        .subtlt {
            font-size: 2em;
            color: $gray;
            margin-bottom: 1em;
            font-family: 'Merienda One', cursive;
        }
        .bg {
            margin-top: 1em;
            margin-left: 1em;
            border: 3px solid $black;
            height: 100%;
            width: 500px;
            height: 350px;
            transition: all 0.5s ease;
            background-color: rgba($color: #fff, $alpha: 0.7);
            img {
                top: -1em;
                left: -1em;
                position: absolute;
                width: 500px;
                transition: all 0.5s ease;
            }
            &:hover {
                margin-top: -2em;
                margin-left: 0em;
                img {
                    top: 2em;
                    left: 2em;
                }
            }
        }
    }
    .SMM {
        background: linear-gradient(133deg, $naranja, #fff, #fff);
        margin: 5em 0;
        color: $gray;
        .main-text {
            padding-bottom: 0!important;
        }
        .subtlt {
            font-size: 2em;
            color: $gray;
            margin-bottom: 1em;
            font-family: 'Merienda One', cursive;
        }
        .bg {
            float: right;
            margin-top: 1em;
            margin-right: 1em;
            border: 3px solid $black;
            height: 100%;
            width: 500px;
            height: 350px;
            transition: all 0.5s ease;
            background-color: rgba($color: #fff, $alpha: 0.7);
            img {
                top: -1em;
                right: -1em;
                position: absolute;
                width: 500px;
                transition: all 0.5s ease;
            }
            &:hover {
                margin-top: -2em;
                margin-right: 0em;
                img {
                    top: 2em;
                    right: 2em;
                }
            }
        }
    }
}

@import "./mediaqueries.scss";