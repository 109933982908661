@import "../constanst/variables.scss";
#mainApps {
    .main-text {
        padding: 1em 0;
        font-size: 2.3em;
        font-family: 'Arima Madurai', cursive;
        font-weight: 900;
        color: $naranja;
    }
    .row {
        padding: 0;
        margin-right: 0;
        margin-left: 0;
    }
    .head {
        background-image: url('../../multimedia/images/apps/movil.jpg');
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 0 0 0 60%;
        .opac {
            text-align: right;
            background-color: rgba($darkBlue, 0.9);
            border-radius: 0 0 0 55%;
            padding: 15em 3em 5em 3em;
        }
        h1,
        h2 {
            color: #fff;
        }
        h2 {
            font-size: 1.5em;
        }
        .btn {
            color: #fff;
            border: 2px solid $naranja;
            margin: 2em 0 1em 0;
            padding: 0.5em 2em;
            font-size: 1.3em;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -ms-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            &:hover {
                background-color: rgba($black, 0.2);
                border: 2px solid $naranja;
                color: #fff;
            }
            &:focus {
                background-color: rgba($black, 0.2);
                border: 2px solid $naranja;
                box-shadow: none;
                color: #fff;
            }
            &:active {
                background-color: rgba($black, 0.2)!important;
                border: 2px solid $naranja!important;
                box-shadow: none!important;
                color: #fff!important;
            }
        }
    }
    .devApps {
        margin-top: 5em;
        margin-bottom: 5em;
        .main-text {
            padding-bottom: 0;
        }
        .sbtlt {
            font-size: 1.5em;
            font-family: 'Arima Madurai', cursive;
            font-weight: 900;
            color: $gray;
            margin-bottom: 3em;
        }
        .wedo {
            .wedotlt {
                color: $darkBlue;
                text-align: center;
            }
            .cotactIcon2 {
                font-size: 5em;
                margin: 0.5em;
                transition: all 0.5s ease;
                &.fa-android {
                    color: $androidGreen;
                }
                &.fa-apple {
                    color: $appleBlack;
                }
                &:hover {
                    transform: rotateY(360deg);
                    color: $naranja;
                }
            }
            .wedoP {
                font-size: 1.2em;
                color: $gray;
            }
        }
        .card {
            border: none;
            .card-header {
                background-color: #fff;
                border-bottom: 3px solid $darkBlueNoHover;
                padding: 0;
                a {
                    text-decoration: none;
                    h5 {
                        padding: 0.5em;
                        color: $naranja;
                        .rotate-icon {
                            color: $naranja;
                            position: absolute;
                            right: 0;
                            font-size: 1em;
                            width: 2em;
                            display: inline-block;
                            transition: all 0.5s ease;
                        }
                    }
                }
                a[aria-expanded="true"] h5 .rotate-icon {
                    -moz-transform: rotate(-180deg);
                    -o-transform: rotate(-180deg);
                    -webkit-transform: rotate(-180deg);
                    transform: rotate(-180deg);
                }
            }
            .card-body {
                color: $gray;
            }
        }
        .brands-dev {
            margin-top: 3em;
            background-image: url('../../multimedia/images/apps/movil negro.jpg');
            background-attachment: fixed;
            background-size: cover;
            background-repeat: no-repeat;
            .icnBrand {
                margin: 0.8em 0;
                font-size: 4em;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                color: rgba(#fff, 0.6);
                transition: all 0.5s ease;
                &:hover {
                    &.fa-amazon {
                        color: #ff9900;
                    }
                    &.fa-google {
                        color: #4285f4;
                    }
                    &.fa-android {
                        color: $androidGreen;
                    }
                    &.fa-apple {
                        color: #fff;
                    }
                    &.fa-react {
                        color: #00d8ff;
                    }
                    border-bottom: 0px solid transparent;
                    border-top: 0px solid transparent;
                }
            }
        }
    }
    .img-phone {
        margin: 10em auto 5em auto;
        // padding: 0;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // min-height: 100vh;
        // background: #36363c;
        .contBox {
            position: relative;
            width: 200px;
            height: 300px;
            background: url('../../multimedia/images/apps/web hl.jpeg');
            background-size: cover;
            transform: rotate(-45deg) skewY(25deg);
            box-shadow: -25px 40px 4px rgba(0, 0, 0, 0.2);
            transition: 0.5s;
            &:hover {
                transform: rotate(-45deg) skewY(25deg) translate(20px, -20px);
                box-shadow: -60px 100px 60px rgba(0, 0, 0, 0.5);
            }
            &:before {
                content: '';
                position: absolute;
                top: 0px;
                left: -20px;
                height: 100%;
                width: 20px;
                background: #e4e4e4;
                box-sizing: border-box;
                /* border-top: 80px solid #ad3c3c; */
                border-top: 30px solid #686868;
                border-bottom: 160px solid #264a7a;
                transform-origin: right;
                transform: skewY(-58deg);
            }
            &:after {
                content: '';
                position: absolute;
                bottom: -32px;
                left: 0;
                height: 32px;
                width: 100%;
                background: #264a7a;
                box-sizing: border-box;
                transform-origin: top;
                transform: skewX(-32deg);
            }
            .glass {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.001);
                box-shadow: 0 0 10px rgba(0, 0, 0, 0);
                transition: 0.5s;
                overflow: hidden;
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -50%;
                    width: 100%;
                    height: 100%;
                    background: rgba(255, 255, 255, 0.05);
                    transform: skewX(-5deg);
                }
            }
            &:hover .glass {
                top: -40px;
                left: 30px;
                background: rgba(255, 255, 225, 0.05);
                box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.2);
            }
            &:hover .glass:nth-child(2) {
                top: -80px;
                left: 60px;
                background: rgba(255, 255, 225, 0.05);
                box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.2);
            }
        }
    }
    .mkApps {
        padding: 0;
        background-image: url('../../multimedia/images/apps/mkapps.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        .main-text {
            padding-bottom: 0;
        }
        .sbtlt {
            font-size: 1.5em;
            font-family: 'Arima Madurai', cursive;
            font-weight: 900;
            color: $gray;
            margin-bottom: 1em;
        }
        .mkAppsTxt {
            padding: 3em 0em 3em 0;
            color: $gray;
            .imgmkapps {
                img {
                    width: 100%;
                }
            }
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

@import "./mediaqueries.scss";