@import "../constanst/variables.scss";
#terms-cond {
    margin-bottom: 8em;
    .nopadding {
        padding: 0;
    }
    .head {
        background-image: url('../../multimedia/images/terminos/office.jpg');
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 0 0 0 60%;
        .opac {
            padding: 12em 3em 12em 3em;
            background-color: rgba($naranja, 0.9);
            border-radius: 0 0 0 55%;
            h1 {
                font-family: 'Lato', sans-serif;
                font-weight: 900;
                font-size: 3em;
                color: #fff;
            }
        }
    }
    .infoemp {
        margin-top: 5em;
        h3 {
            font-family: 'Hammersmith One', sans-serif;
            display: inline-block;
            vertical-align: top;
            zoom: 1;
            /* Fix for IE7 */
            *display: inline;
            /* Fix for IE7 */
            font-size: 1.5em;
            margin: 1em 0;
            color: $black;
            padding-bottom: 0.2em;
            border-bottom: 2px solid $naranja;
        }
        p,
        li {
            font-family: Helvetica, sans-serif;
            text-align: justify;
            padding-left: 2em;
            color: $black;
        }
    }
}

@import "./mediaqueries.scss";