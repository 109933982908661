@import "../../constanst/variables.scss";
#footer {
    border-top: 1px solid rgba(#000, 0.2);
    background-image: url("../../../multimedia/images/Home/citynigth1.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    // margin-top: 3em;
    .bgWhite {
        background-color: rgba(#fff, 0.9);
        padding-bottom: 1em;
        // width: 100%;
        .footer {
            // padding: 1.5em 10em;
            .logo {
                top: -2.5em;
                background-color: #fff;
                border: 1px solid rgba(#000, 0.2);
                a {
                    display: block;
                    padding: 1em 1em;
                    img {
                        max-width: 10em;
                    }
                }
            }
            .slogan {
                h4 {
                    font-size: 4em;
                    color: rgba($black, 0.8);
                    text-align: center;
                    font-family: 'Merienda One', cursive;
                    span {
                        font-size: 1.3em;
                        color: $naranja;
                    }
                    &:hover {
                        background: linear-gradient(to right, $yellow 10%, $naranja 20%, $red 30%, $purple 40%, $blue 50%, $blue 60%, $purple 70%, $red 80%, $naranja 90%, $yellow 100%);
                        background-size: 200% auto;
                        background-clip: text;
                        text-fill-color: transparent;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        animation: txtColor 4s linear infinite;
                    }
                }
            }
            .especialidades {
                padding-bottom: 1em;
                .list-group-flush {
                    width: 100%;
                    background: transparent;
                    a {
                        padding: 0.3em 0.5em;
                        display: inline-block;
                        width: 100%;
                        color: $black;
                        font-size: 1.5em;
                        text-decoration: none;
                        font-family: 'Hammersmith One', sans-serif;
                        border: 1px solid transparent;
                        margin: 0.2em 0;
                        -webkit-transition: all .3s ease;
                        -moz-transition: all .3s ease;
                        -ms-transition: all .3s ease;
                        -o-transition: all .3s ease;
                        transition: all .3s ease;
                        .list-group-item {
                            background: transparent;
                            font-size: 1em;
                            padding: 0.1em;
                            border-top: 0px;
                            border: 0px solid transparent;
                            width: auto;
                        }
                        &:hover {
                            // border: 1px solid $naranja;
                            letter-spacing: 5px;
                            color: $naranja;
                            padding-left: 2em;
                        }
                    }
                }
            }
            .blogFooter {
                h4 {
                    text-align: center;
                    padding-bottom: 1em;
                }
                form {
                    margin-bottom: 1em;
                    .nopadding {
                        padding: 0!important;
                    }
                    input {
                        margin-top: 0em;
                        background-color: rgba($black, 0.1);
                        border-bottom: 1px solid $naranja;
                        border-radius: 0;
                    }
                    .btnhh {
                        margin-top: 0;
                        border-radius: 0;
                        color: $black;
                        &:hover {
                            color: #fff;
                        }
                        &:active {}
                    }
                }
                .socialFollow {
                    margin-top: 2em;
                    h4 {
                        text-align: center;
                        padding-bottom: 1em;
                    }
                    .iconos {
                        a {
                            font-size: 2em;
                            color: #fff;
                            margin: 0 1em;
                            .fa-facebook-f {
                                color: $facebookBlue;
                                transition-timing-function: linear;
                                -webkit-transition: all 0.3s;
                                -moz-transition: all 0.3s;
                                -ms-transition: all 0.3s;
                                -o-transition: all 0.3s;
                                transition: all 0.3s;
                            }
                            .fa-twitter {
                                color: $twitterBlue;
                                transition-timing-function: linear;
                                -webkit-transition: all 0.3s;
                                -moz-transition: all 0.3s;
                                -ms-transition: all 0.3s;
                                -o-transition: all 0.3s;
                                transition: all 0.3s;
                            }
                            .fa-instagram {
                                color: $instagramPurple;
                                transition-timing-function: linear;
                                -webkit-transition: all 0.3s;
                                -moz-transition: all 0.3s;
                                -ms-transition: all 0.3s;
                                -o-transition: all 0.3s;
                                transition: all 0.3s;
                            }
                            &:hover {
                                .fa-facebook-f {
                                    font-size: 1.8em;
                                }
                                .fa-twitter {
                                    font-size: 1.8em;
                                }
                                .fa-instagram {
                                    font-size: 1.8em;
                                }
                            }
                        }
                    }
                }
            }
            hr {
                border-top: 1px solid rgba($black, 0.5);
                z-index: 2;
            }
            .MSyC {
                margin-top: 2em;
                .mapaSitio {
                    a {
                        color: rgba($black, 0.5);
                        text-decoration: none;
                        -webkit-transition: color .25s ease;
                        -moz-transition: color .25s ease;
                        -ms-transition: color .25s ease;
                        -o-transition: color .25s ease;
                        transition: color .25s ease;
                        &:hover {
                            color: rgba($naranja, 0.6);
                        }
                    }
                }
                .copyright {
                    h5 {
                        color: rgba($black, 0.5);
                        font-size: 1em;
                        font-family: Arial;
                    }
                    a {
                        font-size: 0.8em;
                        color: rgba($black, 0.5);
                        text-decoration: none;
                        -webkit-transition: color .25s ease;
                        -moz-transition: color .25s ease;
                        -ms-transition: color .25s ease;
                        -o-transition: color .25s ease;
                        transition: color .25s ease;
                        &:hover {
                            color: rgba($naranja, 0.6);
                        }
                    }
                }
            }
        }
    }
}

.spinnerLoaderFoot {
    margin: auto;
    margin-top: 1em;
    /* position: absolute; */
    color: $black;
    font-size: 2em;
    animation: rotating 2s linear infinite;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes txtColor {
    to {
        background-position: 200% center;
    }
}

@import "./mediaqueries.scss";