@import '../../constanst/variables.scss';
// TODO: CHOOSE A CORRECT FONT FOR THE TITLE IN THIS SECTION
#contactChatSect {
    background-image: url("../../../multimedia/images/contacto/Great Office.png");
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    // background-position: 50% 50%;
    padding-bottom: 2em;
    .bgblack {
        padding-top: 2em;
        padding-bottom: 2em;
        // background-color: rgba($black, 0.5);
        .datosContacto {
            h4 {
                color: #fff;
                font-size: 1.5em;
                font-family: 'Merienda One', cursive;
                margin-bottom: 1em;
            }
            .phones {
                border-right: 1px solid #fff;
                hr {
                    margin: 0.5em 0 1em 0;
                    border-top: 1px solid rgba($naranja, 1);
                    // width: 70%;
                }
                p {
                    margin-bottom: 1em;
                    color: #fff;
                    font-size: 1em;
                }
            }
            // .chats {
            //     p {
            //         margin-bottom: 0;
            //         color: #fff;
            //         font-size: 1em;
            //         a {
            //             color: #fff;
            //             text-decoration: underline;
            //         }
            //         &.iconsChat {
            //             margin: auto;
            //             display: inline-block;
            //             font-size: 1.5em;
            //             margin-bottom: 1em;
            //             &:hover {
            //                 .fa-whatsapp {
            //                     color: $whatsAppGreen;
            //                 }
            //                 .fa-facebook-messenger {
            //                     color: $messengerBlue;
            //                 }
            //             }
            //         }
            //     }
            // }
            .chats2 {
                text-align: center;
                .tl {
                    border-bottom: 1px solid $naranja;
                    border-right: 1px solid $naranja;
                }
                .tc {
                    border-bottom: 1px solid $naranja;
                    border-right: 1px solid $naranja;
                }
                .tr {
                    border-bottom: 1px solid $naranja;
                }
                .cl {
                    border-bottom: 1px solid $naranja;
                    border-right: 1px solid $naranja;
                }
                .cc {
                    border-bottom: 1px solid $naranja;
                    border-right: 1px solid $naranja;
                }
                .cr {
                    border-bottom: 1px solid $naranja;
                }
                .bl {
                    border-right: 1px solid $naranja;
                }
                .bc {
                    border-right: 1px solid $naranja;
                }
                .cotactIcon2 {
                    font-size: 2em;
                    margin: 0.5em 0.2em;
                    color: #fff;
                    transition-timing-function: linear;
                    -webkit-transition: all 0.3s;
                    -moz-transition: all 0.3s;
                    -ms-transition: all 0.3s;
                    -o-transition: all 0.3s;
                    transition: all 0.3s;
                    border-bottom: 1px solid transparent;
                    &:hover {
                        &.fa-facebook-f {
                            color: $facebookBlue;
                            border-bottom: 3px solid $facebookBlue;
                        }
                        &.fa-twitter {
                            color: $twitterBlue;
                            border-bottom: 3px solid $twitterBlue;
                        }
                        &.fa-instagram {
                            color: $instagramPurple;
                            border-bottom: 3px solid $instagramPurple;
                        }
                        &.fa-whatsapp {
                            color: $whatsAppGreen;
                            border-bottom: 3px solid $whatsAppGreen;
                        }
                        &.fa-facebook-messenger {
                            color: $messengerBlue;
                            border-bottom: 3px solid $messengerBlue;
                        }
                    }
                    // &.fa-facebook-f {
                    //     color: $facebookBlue;
                    // }
                    // &.fa-twitter {
                    //     color: $twitterBlue;
                    // }
                    // &.fa-instagram {
                    //     color: $instagramPurple;
                    // }
                    // &.fa-whatsapp {
                    //     color: $whatsAppGreen;
                    // }
                    // &.fa-facebook-messenger {
                    //     color: $messengerBlue;
                    // }
                }
            }
            .siguenos {
                margin-top: 0.5em;
                color: #fff;
                .iconos {
                    margin-top: 0.5em;
                    font-size: 2em;
                    text-align: center;
                    a {
                        padding: 1em;
                        color: #fff;
                        &:hover {
                            .fa-facebook-f {
                                color: $facebookBlue;
                            }
                            .fa-twitter {
                                color: $twitterBlue;
                            }
                            .fa-instagram {
                                color: $instagramPurple;
                            }
                        }
                    }
                }
            }
            .cotactIcon {
                font-size: 1.5em;
                margin-right: 1em;
                &.clOralnge {
                    color: $naranja;
                }
            }
        }
    }
}

@import "./mediaqueries.scss";