@import "../../constanst/variables.scss";
#err404 {
    background: linear-gradient(125deg, $blue 0%, $purple 25%, $red 50%, $naranja 100%);
    // background-color: gradient($blue, $red);
	padding: 12em 0;
	.row{
		
		h1{
            color: #fff;
			font-family: 'Finger Paint', cursive;
			font-size: 4em;
			// text-shadow: 10px 10px 50px #000;
		}
		
		h2{
            color: #fff;
			font-family: 'Finger Paint', cursive;
			// text-shadow: 10px 10px 50px #000;
			font-size: 3em;

		}

		p{
			color: #fff;
			font-size: 22em;
			font-family: Arial;
			// letter-spacing: -70px;
			text-shadow: 10px 10px 50px #000;
			.four1, .four2{
				color: $naranja;
			}
			.four1{
				z-index: 10;
			}
			
			.cero{
				z-index: 5;
			}

			.four2{
				z-index: 0;
				
			}

		}



		a {
			border: 1px solid #000;
			padding: 0.5em 2em;
			color: #fff;
			border-radius: 0.5em;
			font-size: 1.2em;
			font-family: arial;
			background-color: rgba($black, 0.8);
			text-decoration: none;
			&:hover{
				color: $naranja;
				background-color: rgba($black, 1);
			}
		}
	}
}

@import "./mediaqueries.scss";
