@import "../../constanst/variables.scss";
nav#navHL {
    width: 100%;
    border-bottom-left-radius: 50%;
    font-family: 'Oswald', sans-serif;
    right: 0;
    font-weight: 700;
    transition-timing-function: linear;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -ms-transition: all 0.8s;
    -o-transition: all 0.8s;
    transition: all 0.8s;
    #imgLogoNav {
        transition-timing-function: linear;
        -webkit-transition: width 0.8s;
        -moz-transition: width 0.8s;
        -ms-transition: width 0.8s;
        -o-transition: width 0.8s;
        transition: width 0.8s;
    }
    .brandhl {
        a {
            #imgLogoNav {
                width: 100%;
                max-width: 16em;
                transition-timing-function: linear;
                -webkit-transition: min-width 0.8s;
                -moz-transition: min-width 0.8s;
                -ms-transition: min-width 0.8s;
                -o-transition: min-width 0.8s;
                transition: min-width 0.8s;
            }
        }
    }
    .menuHL {
        // margin-left: 8em;
        transition-timing-function: linear;
        -webkit-transition: margin-left 0.8s;
        -moz-transition: margin-left 0.8s;
        -ms-transition: margin-left 0.8s;
        -o-transition: margin-left 0.8s;
        transition: margin-left 0.8s;
    }
    #navbarHome {
        ul {
            li {
                padding: 0 1em;
                &.dropdown:hover>.dropdown-menu {
                    display: block;
                    // border-top: 0.2em solid $naranja;
                    background: rgba($black, 0.8);
                    border-radius: 0px!important;
                    padding: .5em 0 0 0;
                }
                a {
                    font-size: 1em;
                    color: #fff;
                    border-bottom: 2px solid transparent;
                    &:hover {
                        border-bottom: 2px solid $naranja;
                        color: $naranja!important;
                    }
                    &:active {
                        border-bottom: 1px solid $naranja;
                        color: $naranja!important;
                        background-color: rgba($black, 0.8);
                    }
                    &.dropdown-item:focus, &.dropdown-item:hover {
                        background-color: rgba($black, 0.9);
                        border-bottom: 1px solid $naranja;
                    }
                }
            }
        }
        &.dropdown-menu {
            margin-top: 0px;
        }
    }
    button {
        border-color: transparent;
        &:focus {
            border-color: transparent;
            outline: none;
        }
        .btnmenu {
            color: #fff;
            font-size: 1.5em;
            -webkit-transition: all 0.8s;
            -moz-transition: all 0.8s;
            -ms-transition: all 0.8s;
            -o-transition: all 0.8s;
            transition: all 0.8s;
        }
    }
}

@import "./mediaqueriesHeader.scss";