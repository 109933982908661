@import "../constanst/variables.scss";
#mainContact {
    a {
        text-decoration: none;
    }
    padding-bottom: 7em;
    .head {
        background-image: url('../../multimedia/images/contacto/coffee Contact.png');
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 0 0 0 60%;
        .opac {
            padding: 12em 3em 12em 3em;
            margin: 0;
            // background-color: rgba($black, 0.5);
            background-color: rgba($red, 0.6);
            border-radius: 0 0 0 55%;
        }
        h1 {
            font-family: 'Lato', sans-serif;
            font-weight: 900;
            font-size: 5em;
            color: #fff;
        }
        h2 {
            font-family: 'Lato', sans-serif;
            font-weight: 900;
            font-size: 2em;
            color: #fff;
        }
    }
    .formulario {
        background-image: url('../../multimedia/images/contacto/handslightsBLK.jpg');
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        margin: 2em 0;
        padding: 5em 2em 1em 2em;
        // background-color: rgba($black, 1);
        // border-radius: 0.5em;
        box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.5);
        form {
            .input-group {
                // margin: 2em 0em 2em 0em;
                border-bottom: 2px solid $naranja;
                &:hover {
                    box-shadow: none;
                    background-color: rgba(#fff, .2);
                    font-family: Arial;
                    &::placeholder {
                        color: rgba($naranja, .7);
                        font-family: Arial;
                    }
                    .frmIcon {
                        color: $naranja;
                    }
                }
                .form-control {
                    border-bottom: none;
                    font-size: 0.8em;
                    background-color: transparent;
                    border-top: 0px;
                    border-left: 0px;
                    border-right: 0px;
                    font-family: Arial;
                    font-weight: 300;
                    letter-spacing: 1px;
                    border-radius: 0px;
                    color: #fff;
                    &::placeholder {
                        color: rgba(#fff, .7);
                        font-family: Arial;
                        font-weight: 300;
                    }
                }
                .input-group-text {
                    background-color: transparent;
                    border: none;
                    position: relative;
                    justify-content: end;
                }
                .frmIcon {
                    display: inline-block;
                    color: #fff;
                    font-size: 1.5em;
                    transition-timing-function: linear;
                    -webkit-transition: all 0.3s;
                    -moz-transition: all 0.3s;
                    -ms-transition: all 0.3s;
                    -o-transition: all 0.3s;
                    transition: all 0.3s;
                }
            }
            textarea {
                min-height: 12em;
                max-height: 12em;
            }
            .terminos {
                color: #fff;
            }
        }
        .errores {
            margin: 1em 0;
            font-size: 0.8em;
            visibility: hidden;
            p {
                margin-bottom: 0em;
            }
        }
        hr {
            border-top: 1px solid rgba(255, 255, 255, 0.5);
        }
        h3.linestyle,
        .linestylechats {
            text-align: center;
            font-size: 1.5em;
            color: #fff;
            text-transform: uppercase;
            font-family: 'Helvetica', sans-serif;
            position: relative;
        }
        h3.linestyle:before,
        h3.linestyle:after,
        h3.linestylechats:before,
        h3.linestylechats:after {
            position: absolute;
            content: '';
            background-color: $naranja;
            height: 2px;
            top: 13px;
        }
        h3.linestyle:before,
        h3.linestyle:after {
            width: 32%;
        }
        h3.linestylechats:before,
        h3.linestylechats:after {
            width: 30%;
        }
        h3.linestyle:before,
        h3.linestylechats:before {
            left: 0;
        }
        h3.linestyle:after,
        h3.linestylechats:after {
            right: 0;
        }
    }
    .redes {
        .iconos {
            margin-top: 1em;
            margin-bottom: 1em;
            a {
                display: inline-block;
                margin: 1em 0.5em;
                padding: 1em 2em;
                font-size: 1.5em;
                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;
                background-color: transparent;
                border: 2px solid rgba(#fff, 0.5);
                &:hover {
                    -webkit-transform: rotate(360deg);
                    -moz-transform: rotate(360deg);
                    -ms-transform: rotate(360deg);
                    -o-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }
            .facebook {
                color: #fff;
                &:hover {
                    background-color: #3b5998;
                }
            }
            .twitter {
                color: #fff;
                &:hover {
                    background-color: #1da1f2;
                }
            }
            .instagram {
                color: #fff;
                &:hover {
                    background-color: #e1306c;
                }
            }
            .whatsapp {
                color: #fff;
                &:hover {
                    background-color: #25d366;
                }
            }
            .messenger {
                color: #fff;
                &:hover {
                    background-color: #0084ff;
                }
            }
        }
    }
    .infochat {
        color: #fff;
        font-size: 1.2em;
    }
    .telefonos {
        color: #fff;
    }
}

.spinnerContact {
    margin-left: 0.5em;
    margin-top: 0.5em;
    position: absolute;
    z-index: 1000;
    color: #fff;
    font-size: 2em;
    animation: rotating 2s linear infinite;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@import "./mediaqueries.scss";