@import "../../constanst/variables.scss";
.error{
    padding: 0.5em 1em;
    border: 1px solid $red;
    border-radius: 5px;
    margin-top: 1em;
    color: $red;
    ul{
        margin-bottom: 0;
    }
}