@import "../../constanst/variables.scss";
#mainDetalleWorks {
    .row {
        padding: 0;
        margin-right: 0;
        margin-left: 0;
    }
    .nopadding {
        padding: 0;
    }
    .main-text {
        margin-top: 2em;
        padding: 1em 0;
        font-size: 2.3em;
        font-family: 'Arima Madurai', cursive;
        font-weight: 900;
        color: $naranja;
    }
    .head {
        &.hd1 {
            background-image: url("../../../multimedia/images/trabajos/bannerDetalle/banner desarrollo.jpg");
            .opac {
                background-color: rgba($blue, 0.6);
            }
        }
        &.hd2 {
            background-image: url("../../../multimedia/images/trabajos/bannerDetalle/banner marketing.jpg");
            .opac {
                background-color: rgba($red, 0.6);
            }
        }
        &.hd3 {
            background-image: url("../../../multimedia/images/trabajos/bannerDetalle/banner social.jpg");
            .opac {
                background-color: rgba($purple, 0.6);
            }
        }
        &.hd4 {
            background-image: url("../../../multimedia/images/trabajos/bannerDetalle/banner apps.jpg");
            .opac {
                background-color: rgba($darkBlue, 0.6);
            }
        }
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 0 0 0 60%;
        .opac {
            padding: 12em 3em 12em 3em;
            // background-color: rgba($black, 0.5);
            border-radius: 0 0 0 55%;
        }
        h1 {
            font-family: 'Lato', sans-serif;
            font-weight: 900;
            font-size: 4em;
            color: #fff;
        }
        h2 {
            font-family: 'Lato', sans-serif;
            font-weight: 900;
            font-size: 2em;
            color: #fff;
        }
    }
    .title2 {
        margin-top: 1em;
        margin-bottom: 1em;
        font-size: 2em;
        color: $black;
    }
    .description {
        padding: 2em 0;
    }
    .galeriaProyecto {
        // padding: 0 10em;
        margin-bottom: 5em;
        li {
            padding: 1em;
            img {
                max-width: 100%;
                -webkit-box-shadow: 0px 6px 23px 3px rgba(48, 74, 112, 1);
                -moz-box-shadow: 0px 6px 23px 3px rgba(48, 74, 112, 1);
                box-shadow: 0px 6px 23px 3px rgba(48, 74, 112, 1);
            }
        }
    }
    .botones {
        margin-top: 3em;
        margin-bottom: 5em;
        div>.visitarWeb {
            text-decoration: none;
            color: $black;
            border: 1px solid $naranja;
            background-color: #fff;
            padding: .5em 2em;
            -webkit-transition: background-color .25s linear;
            -moz-transition: background-color .25s linear;
            -ms-transition: background-color .25s linear;
            -o-transition: background-color .25s linear;
            transition: background-color .25s linear;
            &:hover {
                background-color: rgba($black, 0.7);
                color: #fff;
                border: 1px solid $black;
            }
        }
    }
    .otrosProyectos {
        margin-top: 5em;
        margin-bottom: 5em;
        img {
            margin-top: 1em;
            -webkit-box-shadow: 0px 6px 23px 3px rgba(48, 74, 112, 1);
            -moz-box-shadow: 0px 6px 23px 3px rgba(48, 74, 112, 1);
            box-shadow: 0px 6px 23px 3px rgba(48, 74, 112, 1);
        }
        .tituloRel {
            margin-top: 1em;
        }
        a {
            text-decoration: none;
            color: $naranja;
            h4 {
                padding: 0.5em 0;
                border-bottom: 1px solid $blue;
                font-size: 1.1em;
            }
            .fa-caret-square-right {
                font-size: 1.2em;
            }
        }
    }
}

@import './mediaqueries.scss';