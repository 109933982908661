@import "../constanst/variables.scss";
#header {
    background-repeat: no-repeat;
    position: relative;
    background-position: 100% 50%;
    background-position-x: 100%;
    background-position-y: 50%;
    min-height: 90vh;
    .bgblack {
        padding-top: 25vh;
        // background: rgba($black, 0.5);
        border-radius: 0 0 0 55%;
        video {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: -1000;
            min-height: 100%;
            max-height: 100%;
            min-width: 100%;
            overflow: hidden;
            object-fit: cover;
            max-width: 100%;
            border-radius: 0 0 0 60%;
        }
        .der {
            padding-top: 3em;
            padding-right: 1em;
            padding-bottom: 2em;
            h1 {
                color: #fff;
                font-family: 'Lato', sans-serif;
                font-weight: 900;
                font-size: 3.2em;
            }
        }
        .pasion {
            padding-top: 15vh;
            padding-bottom: 5vh;
            position: relative;
            bottom: 1em;
            h2 {
                padding: 0 0.5em;
                color: #fff;
                font-size: 1.5em;
            }
            .go {
                color: $naranja;
                font-size: 3em;
            }
        }
    }
}

.sols {
    padding-top: 5em!important;
    color: #fff;
    // margin-bottom: 5em;
    .title {
        margin-bottom: 3em!important;
        h2 {
            font-family: 'Arima Madurai', cursive;
            font-weight: 900;
            font-size: 2em;
            color: $naranja;
        }
    }
    div.imagen {
        padding: 0;
        width: 100%;
        height: 35em;
        background-size: cover;
        a {
            text-decoration: none;
            color: #fff;
        }
        &.imgDev {
            background-image: url("../../multimedia/images/Home/portf/devWeb.jpg");
        }
        &.imgSoMe {
            background-image: url("../../multimedia/images/Home/portf/socialMedia.jpg");
        }
        &.imgMark {
            background-image: url("../../multimedia/images/Home/portf/Marketing.jpg");
        }
        &.imgApps {
            background-image: url("../../multimedia/images/Home/portf/devApps.jpg");
        }
        div.info {
            overflow: hidden;
            width: 100%;
            height: 100%;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -ms-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            h3.headline {
                position: absolute;
                top: 45%;
                font-size: 2rem;
                width: 100%;
                text-align: center;
                color: $naranja;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                -ms-transition: all .3s ease;
                -o-transition: all .3s ease;
                transition: all .3s ease;
                span {
                    color: #fff;
                }
            }
            p.descripcion {
                padding: 2em 1em 0 0.8em;
                font-size: 1.2em;
                text-align: center;
                margin-top: 200px;
                transition: margin-top 0.4s;
                opacity: 0;
            }
            .svg {
                // width: 1em;
                // display: block;
                // vertical-align: top;
                // zoom: 1;
                // /* Fix for IE7 */
                // *display: inline;
                // /* Fix for IE7 */
                // margin: auto;
                // transition: width 0.4s;
                opacity: 0;
            }
            // &:hover .svg {
            //     margin-top: 0px;
            //     width: 5em;
            // }
        }
        .vermas {
            width: 100%;
            position: absolute;
            display: block;
            top: 30%;
            opacity: 0;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -ms-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            .verbtn {
                padding: 0.5em 2.5em;
                font-size: 1em;
                border: 1px solid $naranja;
                &:hover {
                    &.blcl {
                        background: rgba($blue, 1);
                    }
                    &.prpcl {
                        background: rgba($purple, 1);
                    }
                    &.rdcl {
                        background: rgba($red, 1);
                    }
                    &.ylcl {
                        background: rgba($darkBlue, 1);
                    }
                }
            }
        }
        &:hover div.info {
            h3.headline {
                color: #fff;
                span {
                    color: $naranja;
                }
            }
            .vermas {
                opacity: 1;
                top: 70%;
            }
            p.descripcion {
                margin-top: 0px;
                opacity: 1;
            }
            &.devweb {
                background-color: rgba($blue, 0.7);
            }
            &.socmed {
                background-color: rgba($purple, 0.7);
            }
            &.mkting {
                background-color: rgba($red, 0.7);
            }
            &.devapps {
                background-color: rgba($darkBlue, 0.7);
            }
        }
    }
}

#weare {
    margin-top: 5em;
    margin-bottom: 5em;
    color: $gray;
    .wearetitle {
        font-size: 2.2em;
        margin-bottom: 2em;
        background-size: 400%;
        strong {
            color: $naranja;
        }
        &:hover {
            // animation: animate 8s linear infinite;
            background: linear-gradient(to right, $yellow 10%, $naranja 20%, $red 30%, $purple 40%, $blue 50%, $blue 60%, $purple 70%, $red 80%, $naranja 90%, $yellow 100%);
            background-size: 200% auto;
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: animate 2s linear infinite;
        }
    }
    .weareCont {
        transition: all .5s ease;
        b {
            font-size: 2em;
            font-weight: bolder;
            color: $naranja;
        }
        .weareIcon {
            font-size: 2.5em;
            color: $gray;
            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;
        }
        p.mb-0 {
            font-size: 1.2em;
            padding: 1em 0;
            strong {
                color: $naranja;
            }
        }
        &:hover {
            transform: rotate(360deg);
            .weareIcon {
                color: $naranja;
                border-top: 3px solid $naranja;
                border-bottom: 3px solid $naranja;
            }
            b {
                display: none;
                color: $blue;
            }
            p.mb-0 {
                color: $naranja;
            }
        }
    }
}

#ourClients {
    margin-top: 2em;
    margin-bottom: 4em;
    color: $gray;
    .ourClientstitle {
        font-size: 2.2em;
        background-size: 400%;
        strong {
            color: $naranja;
        }
    }
    .imgClient {
        width: 120px;
        height: auto;
        transition: all .5s ease;
        filter: grayscale(1);
        &:hover {
            filter: grayscale(0);
        }
    }
}

#brochure {
    margin-top: 5em;
    margin-bottom: 5em;
    .tlh3 {
        color: $naranja;
        font-size: 3em;
        margin-bottom: 1em;
    }
    p {
        font-size: 1.2em;
        color: $gray;
    }
    .btnBrochure {
        // background-color: $gray;
        &:hover {
            .fa-arrow-down {
                animation-duration: 1s;
                animation-name: scales;
                animation-iteration-count: infinite;
            }
        }
        .fa-arrow-down {
            text-align: center;
            font-size: 8em;
            margin-bottom: 30px;
            color: $yellow;
        }
        .btnhh {
            color: $gray;
            text-decoration: none;
            position: relative;
            padding: 0.5em 3em;
            border: 1px solid $naranja;
            background: #fff;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            -ms-transition: all .3s ease;
            -o-transition: all .3s ease;
            transition: all .3s ease;
            &:hover {
                background-color: $naranja;
                border: 1px solid $naranja;
                color: #fff;
                .fa-file-download {
                    color: #fff;
                }
            }
            .fa-file-download {
                display: inline;
                margin-left: 0.5em;
                font-size: 1.5em;
            }
        }
    }
}

#pilars {
    overflow: hidden;
    background-image: url("../../multimedia/images/Home/pilares/como lo hacemos 1.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    .colcol2 {
        border-bottom: 2px solid rgba(255, 255, 255, 0.5);
        z-index: 1;
        padding: 0;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        h2 {
            width: 100%;
            padding: 0.5em 0 !important;
            text-align: center;
            margin: 0;
            padding: 0;
            color: rgba(255, 255, 255, 0.5);
            font-size: 3em;
            transition: 0.5s;
            &:hover {
                color: rgba(255, 255, 255, 1);
                background-color: rgba(0, 0, 0, 0.5);
            }
        }
    }
    .colcol {
        padding: 15em 0;
        border-right: 2px solid rgba(255, 255, 255, 0.5);
        z-index: 1;
        border-bottom: 2px solid rgba(255, 255, 255, 0.5);
        background: rgba(0, 0, 0, 0.1);
        &:last-child {
            border-right: none;
        }
        .contenido {
            position: relative;
            height: 100%;
        }
        h2 {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            text-align: center;
            margin: 0;
            padding: 0;
            color: rgba(255, 255, 255, 0.7);
            font-size: 2em;
        }
        .contentTxt {
            position: absolute;
            top: 50%;
            transform: translateY(100%);
            box-sizing: border-box;
            padding: 40px;
            background-color: rgba(255, 255, 255, 0.5);
            text-align: center;
            transition: 0.7s;
            opacity: 0;
            h3 {
                margin: 0;
                padding: 0 0 20px 0;
                font-size: 30px;
                color: #262626;
            }
            p {
                font-size: 18px;
                color: #262626;
            }
        }
        &:hover .contentTxt {
            opacity: 1;
            transform: translateY(-50%);
        }
        &:hover h2 {
            display: none;
        }
        .bg {
            position: absolute;
            top: 25em;
            left: 25em;
            width: 1%;
            height: 1%;
            pointer-events: none;
            z-index: -1;
            transition: all 2 linear;
        }
        &:hover .bg.bg1 {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../../multimedia/images/Home/pilares/como lo hacemos 2.jpg");
            background-size: cover;
            background-attachment: fixed;
            background-position: center;
            transition: 0.7s linear;
        }
    }
}

.us2 {
    padding: 5em 0em 5em 0;
    p {
        font-family: 'Helvetica';
        margin-left: 1em;
        margin-right: 1em;
        font-size: 1em;
        // font-weight: bold;
        color: $black;
        .color {
            font-size: 1.5em;
            color: $naranja;
        }
    }
}

@keyframes scales {
    0% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(-5deg);
    }
}

@keyframes animate {
    to {
        background-position: 200% center;
    }
}

@import "./mediaqueries.scss";