@import '../../constanst/variables.scss';
.banner {
    // margin-top: 3em;
    // margin: 0 0 5em 0;
    padding: 0;
    .slide {
        padding: 0;
        height: 40em;
        .carousel-inner {
            height: 100%;
            .slide1 {
                background-image: url("../../../multimedia/images/Home/banner/estates.png");
                background-size: cover;
                height: 100%;
                color: $black;
                .slide1-left {
                    position: absolute;
                    bottom: 10%;
                    h2 {
                        font-family: 'Lato', sans-serif;
                        font-weight: 900;
                        font-size: 4em;
                        padding-top: 0.5em;
                        padding-left: 1em;
                        color: #fff;
                        span {
                            color: $naranja;
                        }
                    }
                    h3 {
                        padding-left: 1em;
                        padding-top: 0em;
                        font-family: 'Cantata One', serif;
                        font-size: 1em;
                        padding-top: 0.5em;
                        .btnhh {
                            padding: 0.6em 2em;
                            text-decoration: none;
                            border-radius: 3px;
                        }
                    }
                    h4 {
                        padding-top: 0.5em;
                        color: $blue;
                    }
                    // a{
                    // 	color: #fff;
                    // 	font-size: 1.1em;
                    // 	text-decoration: none;
                    // 	padding: 1em 3em;
                    // 	background-color: $blue;
                    // }
                }
                .slide1-right {
                    img {
                        width: 47em;
                    }
                }
            }
            .slide2 {
                background-image: url("../../../multimedia/images/Home/banner/Marketing process.png");
                background-size: cover;
                background-position: center;
                height: 100%;
                padding-top: 3em;
                color: #fff;
                .slide2-center {
                    height: 100%;
                    position: absolute;
                    bottom: -70%;
                    right: 0;
                    h2 {
                        font-family: sans-serif;
                        font-weight: 900;
                        font-size: 3em;
                        // padding-top: 0.5em;
                        color: #fff;
                        span {
                            color: $naranja;
                        }
                    }
                    h3 {
                        margin-top: 1em;
                        padding-top: 0em;
                        font-family: 'Cantata One', serif;
                        font-size: 1em;
                        padding-top: 0.5em;
                        .btnhh {
                            padding: 0.6em 2em;
                            text-decoration: none;
                            border-radius: 3px;
                        }
                    }
                    h4 {
                        padding-top: 0.5em;
                    }
                }
            }
            .slide3 {
                background-image: url("../../../multimedia/images/Home/banner/contact users.png");
                height: 100%;
                background-size: cover;
                background-position: right;
                // bottom: 0px;
                padding-top: 5em;
                h2 {
                    font-family: 'Lato', sans-serif;
                    font-weight: 900;
                    font-size: 4em;
                    padding-top: 0.5em;
                    padding-right: 1em;
                    color: #fff;
                    span {
                        color: $naranja;
                    }
                }
                h3 {
                    margin-top: 1em;
                    padding-top: 0em;
                    font-family: 'Cantata One', serif;
                    font-size: 1.2em;
                    padding-top: 0.5em;
                    color: #fff;
                    padding-right: 1em;
                    .btnhh {
                        padding: 0.6em 2em;
                        text-decoration: none;
                        border-radius: 3px;
                    }
                }
                h4 {
                    padding-top: 0.5em;
                    color: #fff;
                }
            }
            .slide4 {
                background-image: url("../../../multimedia/images/Home/banner/glassBlur.jpg");
                height: 100%;
                background-size: cover;
                background-position: center center;
                padding-top: 5em;
                color: #fff;
                .bgc {
                    padding: 2em 5em;
                    background-color: rgba($black, 0.7);
                    border: 1px solid $naranja;
                }
                h2 {
                    font-family: 'Lato', sans-serif;
                    font-weight: 900;
                    font-size: 4em;
                    padding-top: 0.5em;
                }
                h3 {
                    padding-top: 0em;
                    font-family: 'Cantata One', serif;
                    font-size: 2em;
                    padding-top: 0.5em;
                }
                h4 {
                    padding-top: 0.5em;
                }
            }
        }
    }
}

@import './mediaqueries.scss';