// ResponsiveSnp

/*=============================================
ESCRITORIO SUPER GRANDE (XL revisamos en 1366px en adelante)
=============================================*/

// Extra Large
@media (min-width: 1200px) {}


/*=============================================
ESCRITORIO GRANDE O TABLET HORIZONTAL (LG revisamos en 1024px)
=============================================*/

// Large
@media (min-width: 992px) and (max-width: 1199px) {
    #mainDetalleWorks {
        .galeriaProyecto {
            .img1 {
                margin-bottom: 1.5em;
            }
        }
    }
}


/*=============================================
ESCRITORIO MEDIANO O TABLET VERTICAL (MD revisamos en 768px)
=============================================*/

// Medium
@media (min-width: 768px) and (max-width: 991px) {
    #mainDetalleWorks {
        .main-text {
            margin-top: 1em;
        }
        .head {
            .opac {
                padding: 8em 3em 6em 3em;
                h1 {
                    font-size: 3em;
                }
            }
        }
        .title2 {
            font-size: 1.7em;
        }
        .description {
            padding: 0em 0;
        }
        .botones {
            margin-top: 0;
            margin-bottom: 1em;
        }
        .otrosProyectos {
            margin-top: 3em;
            margin-bottom: 5em;
            .a {
                h4 {
                    font-size: 1em;
                }
            }
        }
    }
}


/*=============================================
MOVIL HORIZONTAL O MINI TABLET VERTICAL (sm revisamos en 576 y 767)
=============================================*/

// Small
@media (min-width: 576px) and (max-width: 767px) {
    #mainDetalleWorks {
        .main-text {
            font-size: 2em;
            margin-top: 0.5em;
        }
        .head {
            .opac {
                padding: 8em 3em 6em 3em;
                h1 {
                    font-size: 2.5em;
                }
            }
        }
        .title2 {
            font-size: 1.7em;
        }
        .description {
            padding: 0em 0;
        }
        .botones {
            margin-top: 0;
            margin-bottom: 1em;
        }
        .otrosProyectos {
            margin-top: 3em;
            margin-bottom: 5em;
            .a {
                h4 {
                    font-size: 1em;
                }
            }
        }
    }
}


/*=============================================
MOVIL (XS revisamos en 320px)
=============================================*/

// extra small
@media (max-width: 575px) {
    #mainDetalleWorks {
        .main-text {
            font-size: 1.5em;
            margin-top: 0.5em;
        }
        .head {
            .opac {
                padding: 7em 3em 6em 3em;
                h1 {
                    font-size: 2em;
                }
            }
        }
        .title2 {
            font-size: 1.3em;
        }
        .description {
            padding: 0em 0;
        }
        .botones {
            margin-top: 0;
            margin-bottom: 1em;
        }
        .galeriaProyecto {
            li {
                padding: 0.2em 0.2em;
            }
        }
        .otrosProyectos {
            margin-top: 4em;
            .a {
                h4 {
                    font-size: 1em;
                }
            }
        }
    }
}