@import "../constanst/variables.scss";
#mainTrabajos {
    .row {
        padding: 0;
        margin-right: 0;
        margin-left: 0;
    }
    .nopadding {
        padding: 0;
    }
    .head {
        background-image: url('../../multimedia/images/trabajos/wereOpen.jpg');
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 0 0 0 60%;
        .opac {
            padding: 12em 3em 12em 3em;
            background-color: rgba($red, 0.5);
            border-radius: 0 0 0 55%;
        }
        h1 {
            font-family: 'Lato', sans-serif;
            font-weight: 900;
            font-size: 3em;
            color: #fff;
        }
    }
    .menuWorks {
        margin-top: 5em;
        // margin-bottom: 5em;
        ul {
            justify-content: center;
        }
        h2 {
            font-size: 1em;
            padding: 0.5em 0.1em;
            button {
                // text-decoration: none;
                width: 100%;
                background-color: #fff;
                border: 1px solid $black;
                color: $black;
                border-radius: 0;
                &:hover {
                    border: 1px solid $naranja;
                    color: $naranja;
                }
            }
        }
        .pestActiva {
            border: 1px solid $naranja;
            color: $naranja;
            font-weight: 600;
        }
    }
    .worksItems {
        margin-top: 15em;
        margin-bottom: 5em;
    }
    .soon {
        margin: auto;
        margin-top: -12em;
        margin-bottom: 1em;
        font-family: 'Merienda', cursive;
        h3 {
            // display: inline;
            span {
                font-size: 1.7em;
                color: $naranja;
            }
            font-size: 1.5em;
        }
        h4 {
            margin-top: 1em;
            font-size: 2em;
            margin-bottom: 1em;
            span {
                color: $naranja;
            }
        }
        .imgContainer {
            text-align: center;
            margin-top: 1em;
            img {
                // margin: auto;
                width: 100%;
            }
        }
    }
}

@import "./mediaqueries.scss";