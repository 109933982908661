$naranja: #ffa500;
$black: #313B42;
$blue: #225B9C;
$blueNoHover: #386ba6;
$red: #ff4c4c;
$purple: #833ab4;
$yellow: #fcaf45;
$darkBlue:#003087;
$darkBlueNoHover: #003087;
$gray: #979ca0;
// Brands colors
$facebookBlue: #3b5998;
$twitterBlue: #1da1f2;
$instagramPurple: #e1306c;
$youTubeRed: #ff0000;
$messengerBlue: #0084ff;
$whatsAppGreen: #25d366;
$androidGreen: #a4c639;
$appleBlack: #1f2020;
.nopadding {
    padding: 0;
}

.nomargin {
    margin: 0;
}

.btnhh {
    width: 100%;
    background: rgba(#fff, 0.2);
    color: #fff;
    border: 1px solid $naranja;
    margin-top: 1em;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    &:hover {
        background-color: rgba($black, 0.2);
        border: 1px solid $naranja;
        color: #fff;
    }
    &:focus {
        background-color: rgba($black, 0.2);
        border: 2px solid $naranja;
        box-shadow: none;
        color: #fff;
    }
    &:active {
        background-color: rgba($black, 0.2)!important;
        border: 2px solid $naranja!important;
        box-shadow: none!important;
        color: #fff!important;
    }
}

.titleSwal {
    color: $naranja;
    font-size: 0.8em;
}

.swal2-confirm {
    background-color: transparent!important;
    border-radius: none !important;
    border: 2px solid $naranja!important;
    color: $black!important;
}