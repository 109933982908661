@import "../../constanst/variables.scss";
#loader {
    margin: auto;
    .loader {
        margin: auto;
        margin-top: -10em;
        position: relative;
        width: 6em;
        height: 6em;
        border-radius: 50%;
        background: linear-gradient(45deg, transparent, transparent 40%, $yellow);
        animation: rotateLoader 1.5s linear infinite;
        &:before {
            content: '';
            position: absolute;
            top: 6px;
            left: 6px;
            right: 6px;
            bottom: 6px;
            background: #fff;
            border-radius: 50%;
            z-index: 1000;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background: linear-gradient(45deg, transparent, transparent 40%, $yellow);
            border-radius: 50%;
            z-index: 1;
            filter: blur(30px);
        }
    }
}

@keyframes rotateLoader {
    0% {
        transform: rotate(0deg);
        filter: hue-rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        filter: hue-rotate(360deg);
    }
}